import React, { FC, forwardRef } from 'react';
import { SvgIconProps } from '@material-ui/core';
import { ReactComponent as FooterLogoSvg } from './footerLogoSvg.svg';
import { ReactComponent as LocationIconSvg } from './locationIconSvg.svg';
import { ReactComponent as PhoneIconSvg } from './phoneIconSvg.svg';
import { ReactComponent as LinkedinIconSvg } from './linkedinIconSvg.svg';
import { ReactComponent as FacebookIconSvg } from './facebookIconSvg.svg';
import { ReactComponent as ArrowIconSvg } from './arrowIconSvg.svg';
import { ReactComponent as TermsOfUseSvg } from './termsOfUse.svg';
import { ReactComponent as PrivacyPolicySvg } from './privacyPolicy.svg';

export const FooterLogo: FC<SvgIconProps> = forwardRef((props, ref) => <FooterLogoSvg ref={ref} {...props} />);
export const LocationIcon: FC<SvgIconProps> = forwardRef((props, ref) => <LocationIconSvg ref={ref} {...props} />);
export const PhoneIcon: FC<SvgIconProps> = forwardRef((props, ref) => <PhoneIconSvg ref={ref} {...props} />);
export const LinkedinIcon: FC<SvgIconProps> = forwardRef((props, ref) => <LinkedinIconSvg ref={ref} {...props} />);
export const FacebookIcon: FC<SvgIconProps> = forwardRef((props, ref) => <FacebookIconSvg ref={ref} {...props} />);
export const ArrowIcon: FC<SvgIconProps> = forwardRef((props, ref) => <ArrowIconSvg ref={ref} {...props} />);
export const TermsOfUse: FC<SvgIconProps> = forwardRef((props, ref) => <TermsOfUseSvg ref={ref} {...props} />);
export const PrivacyPolicy: FC<SvgIconProps> = forwardRef((props, ref) => <PrivacyPolicySvg ref={ref} {...props} />);
